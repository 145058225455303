<template>
  <div class="wrap">
    <div class="top_title">
      <!-- <div class="title1">{{ $t("login.title1") }}</div>
      <div class="title2">{{ $t("login.title2") }}</div> -->
      <img :src="require('@/assets/Img/login/login_title.png')" alt="">
    </div>
    <div class="form_bar">
      <LoginType v-model="loginType" v-if="!verConfig.onlyEmailLogin && !verConfig.onlyPhoneLogin" />
      <div class="inputs" :style="(verConfig.onlyEmailLogin || verConfig.onlyPhoneLogin) && {
        marginTop: 0,
      }
        ">
        <div v-for="(item, index) in formList" :key="index" class="border-bottom-calss">
          <i class="iconfont" :class="item.icon"></i>
          <div :style="{
        'justify-content': item.key === 'userName' ? '' : 'space-between',
      }">
            <span v-if="item.key === 'userName' &&
        verConfig.areaCode &&
        loginType == 'phone'
        " style="
                padding-left: 15px;
                padding-right: 12px;
                border-right: 1px solid #ccc;
                border-left: 1px solid #ccc;
                color: var(--sizeColor);
                display: flex;
              ">+
              <AreaCodePicker v-model="areaCode" />
            </span>
            <input :type="item.type" :placeholder="item.placeholder" :maxlength="item.length" v-model="form[item.key]"
              :adjust-position="true" @focus="handleOnfocus" @blur="handleOnblur" />
            <i class="iconfont pwd_icons" v-if="item.key === 'password' || item.key === 'againPassword'"
              :class="item.flag === true ? 'icon-yanjing' : 'icon-yanjing-bi'" @click="handleLookPass"></i>
          </div>
        </div>
      </div>
      <van-checkbox v-model="checked" shape="round" class="checkbox" checked-color="var(--newTheme)">{{
        $t("login.rememberPassword") }}</van-checkbox>
      <div class="user_agreement" v-if="!verConfig.registerHasRead">
        <van-checkbox v-model="userAgreementChecked" shape="round" class="checkbox2" checked-color="var(--newTheme)">
        </van-checkbox>
        <router-link to="/UserAgreement" class="link">{{
        $t("login.userAgreement")
      }}</router-link>
      </div>
      <div class="service_bar" @click="toService">
        <i class="iconfont icon-kefu"></i>
        <div class="p">
          <div class="p1">{{ $t("login.forgetPassword").split(",")[0] }},</div>
          <div class="p2">{{ $t("login.forgetPassword").split(",")[1] }}</div>
        </div>
      </div>
      <div class="btn_login" @click="handleLogin" :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : {}">
        {{ $t("Login.loginButton") }}
      </div>
      <div class="btn_registe" @click="handleTable('registrasi')">
        <div class="hr">{{ $t("Login.registrasi") }}</div>
      </div>
      
    </div>

    <div class="lan_bar" v-if="verConfig.loginSetLans">
      <SetLans v-model="areaCode" />
    </div>
    <LoadingRE :show="LoadingShow" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Toast,
  Checkbox,
  CheckboxGroup,
  Dialog,
} from "vant";

// 设置vant的多语言
import md5 from "js-md5";
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import { getNewPhone } from "@/utils/tools";
import { mapGetters } from "vuex";

import { REQUEST_API, REQUEST_API2, REQUEST_API3 } from "@/http/api";
import { LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";
import AreaCodePicker from "@/components/areaCodePicker";
import LoginType from "@/components/loginType";
import SetLans from "@/components/setLans";
import verConfig from "@/utils/verConfig";
import i18n from "@/i18n";

const phoneItem = {
  placeholder: `${i18n.t("Login.nameInput")}`,
  icon: "icon-wode",
  type: "tel",
  key: "userName",
  length: 11,
};
const emailItem = {
  placeholder: `${i18n.t("Login.emailInput")}`,
  icon: "icon-wode",
  type: "text",
  key: "userName",
  length: 99,
  onlyEmeil: true,
};

const _currentLoginType = () => {
  if (verConfig.onlyEmailLogin) {
    return "email";
  }
  return "phone";
};

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    LoadingRE,
    AreaCodePicker,
    LoginType,
    SetLans,
  },
  data() {
    return {
      loginType: _currentLoginType(),
      LoadingShow: false,
      areaCode: "",
      message: "copy",
      country: Cookies.get("language"),
      form: {
        userName: "",
        password: "",
      },
      formList: [
        {
          placeholder: `${this.$t("Login.nameInput")}`,
          // icon: require("@/assets/Img/login/icon/userName.png"),
          icon: "icon-wode",
          key: "userName",
          type: "tel",
          length: 11,
        },
        {
          placeholder: `${this.$t("Login.pwdInput")}`,
          // icon: require("@/assets/Img/login/icon/password.png"),
          key: "password",
          icon: "icon-jiesuo",
          type: "password",
          length: 99,
          flag: false,
        },
      ],
      tablelist: [
        // {
        //   label: `${this.$t('Login.forgot')}`,
        //   key: 'forgot',
        // },
        {
          label: `${this.$t("Login.registrasi")}`,
          key: "registrasi",
        },
      ],
      isBottomIconShow: true,
      checked: false,
      thePwdObj: {
        userName: "",
        passwordL: "",
      },
      show: false,
      userAgreementChecked: true,
    };
  },
  watch: {
    loginType(value) {
      switch (value) {
        case "phone":
          this.formList[0].placeholder = this.$t("Login.nameInput");
          this.formList[0].type = "tel";
          this.formList[0].length = 11;
          break;
        case "email":
          this.formList[0].placeholder = this.$t("Login.emailInput");
          this.formList[0].type = "text";
          this.formList[0].length = 99;
          break;
        default:
          break;
      }
    },
    areaCode(val) {
    },
  },
  created() {
    this.init();
    this.isBottomIconShow = true;
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("token");
    if (this.verConfig.onlyEmailLogin) {
      this.formList[0] = emailItem;
    }
  },
  methods: {
    toService() {
      this.$api.toService();
    },
    onCopy(e) {
      // console.log(e.text)
    },
    onError(e) {
      console.log("失败");
    },
    handleOnfocus() {
      this.isBottomIconShow = false;
      // console.log('focus', this.isBottomIconShow)
    },
    handleOnblur() {
      if (!this.isBottomIconShow) {
        this.isBottomIconShow = true;
      } else {
        setTimeout(() => {
          this.isBottomIconShow = true;
        }, 200);
      }
    },
    onSelect(e) {
      let _this = this;
      _this.country = e.type;
      console.log("country: ", country);
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
        case "idn":
          // window.console.log(e.type)
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
      }
      this.show = false;
    },
    onClose() {
      this.show = false;
    },
    /** handle **/
    handleLookPass() {
      if (this.formList[1].type === "password") {
        this.formList[1].type = "text";
        this.formList[1].flag = true;
      } else {
        this.formList[1].type = "password";
        this.formList[1].flag = false;
      }
    },
    handleLogin() {
      let count = 0;
      for (let key in this.form) {
        if (this.form[key]) {
          count++;
        }
      }
      if (count > 1) {
        // Toast(this.$router.history.current.name)
        this.login();
      } else {
        Toast(this.$t("Hint.notInput")); // 输入内容不完整
      }
    },
    handleTable(key) {
      this.$router.push(key);
    },
    /** API **/
    login() {
      let form = {
        userphone: this.form.userName,
        userpass: this.form.password,
        country_code: this.areaCode,
      };
      const emailReg =
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
      if (this.loginType === "email") {
        form.userphone = this.form.userName;
        form.country_code = "";
        if (!emailReg.test(form.userphone)) {
          Toast(this.$t("emailFormatError"));
          return;
        }
      }
      // this.LoadingShow = true;
      this.$store.dispatch("login", form).then((res) => {
        console.log(res, ">>>");
      });
    },
    // login() {
    //   let form = {
    //     userphone: this.form.userName,
    //     userpass: this.form.password,
    //     country_code: this.areaCode,
    //   };
    //   const emailReg =
    //     /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    //   if (this.loginType === "email") {
    //     form.userphone = this.form.userName;
    //     form.country_code = "";
    //     if (!emailReg.test(form.userphone)) {
    //       Toast(this.$t("emailFormatError"));
    //       return;
    //     }
    //   }
    //   this.LoadingShow = true;
    //   LOGIN(form) // http请求
    //     .then((r) => {
    //       this.$store.commit("SET_LOGIN_INFO", {
    //         phone: getNewPhone(this.form.userName),
    //         pwd: this.form.password,
    //         channel: Modelu.signStrFunc(),
    //       });
    //       if (r.data.ret === 1) {
    //         this.$cookie.set("userInfo", JSON.stringify(r.data.data));
    //         this.$cookie.set("token", r.data.data.token);
    //         this.$cookie.set("the-pwd-state", this.checked);
    //         if (this.checked) {
    //           this.$cookie.set(
    //             "the-pwd-obj",
    //             JSON.stringify({
    //               userName: this.form.userName,
    //               password: this.form.password,
    //             })
    //           );
    //         } else {
    //           this.$cookie.remove("the-pwd-obj");
    //         }
    //       } else {
    //         this.LoadingShow = false;
    //         Toast(r.data.msg);
    //       }
    //       return r.data.ret;
    //     })
    //     .then((ret) => {
    //       if (ret === 1) {
    //         this.$api.getUserInfo(
    //           (res) => {},
    //           (err) => {},
    //           () => {
    //             this.LoadingShow = false;
    //             this.$router.replace({ name: "goods" });
    //           },
    //           this.$cookie.get("token")
    //         );
    //       }
    //     })
    //     .catch((e) => {
    //       this.LoadingShow = false;
    //       console.log(e);
    //     });
    // },
    /** 其他 **/
    init() {
      let storePwdState = localStorage.getItem("the-pwd-state");
      let storePwd = localStorage.getItem("the-pwd-obj");
      let initObj = {
        userName: "",
        passwordL: "",
      };
      this.checked =
        storePwdState && storePwdState !== "undefined"
          ? JSON.parse(storePwdState)
          : false;
      this.form = storePwd
        ? JSON.parse(storePwd)
          ? JSON.parse(storePwd)
          : initObj
        : initObj;
    },
  },
  computed: {
    ...mapGetters(["theme"]),
  },
};
</script>

<style lang="less" scoped>
@checkbox-label-color: #88888d;
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.wrap {
  min-height: calc(100vh - (30rem / 16));
  background-image: url('../../assets/Img/login/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: calc(15rem / 16);
  overflow: hidden;
  position: relative;

  .top_title {
    margin-top: calc(8rem / 16);
    padding: calc(32rem / 16) 0;
    padding-left: 0;
    font-size: calc(26rem / 16);
    color: var(--theme);
    display: flex;
    justify-content: center;

    .title2 {
      margin-top: calc(14rem / 16);
      color: var(--theme);
    }

    img {
      height: calc(54rem / 16);
    }
  }

  .form_bar {
    // background: #fff;
    background: @bg;
    border-radius: calc(12rem / 16);
    padding: calc(18rem / 16) calc(14rem / 16);

    .inputs {
      margin-top: calc(20rem / 16);

      >.border-bottom-calss {
        border-bottom: 0.0625rem solid #cbcbcb;

        >.iconfont {
          // color: var(--color, orangered);
          font-size: calc(22rem / 16);
          padding-right: calc(6rem / 16);
          // color: #000;
          color: var(--iconColor);
          // font-weight: 600;
        }
      }

      >div {
        display: flex;
        align-items: center;
        padding: 0.625rem calc(10rem / 16);

        >img:nth-child(1) {
          width: calc(18rem / 16);
          height: calc(18rem / 16);
          margin-right: calc(8rem / 16);
        }

        >div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          >input {
            // width: calc(220rem / 16);
            flex-grow: 1;
            border: 0;
            // border-left: calc(1rem / 16) solid #cbcbcb;
            padding-left: calc(5rem / 16);
            font-size: calc(15rem / 16);
            // color: #000;
            color: @Color;
            background-color: transparent;
          }

          >img:nth-last-child(1) {
            width: calc(30rem / 16);
            margin-right: calc(15rem / 16);
          }

          .pwd_icons {
            font-size: calc(28rem / 16);
            // color: var(--color, #333);
            color: var(--iconColor);
          }
        }
      }

      >div:nth-child(1) {
        margin-bottom: calc(12rem / 16);
      }

      >button {
        width: 100%;
        // margin-top: calc(40rem / 16);
        color: #ffffff;
        font-size: calc(15rem / 16);
      }
    }

    .checkbox {
      font-size: calc(12rem / 16);
      margin-top: calc(12rem / 16);
      margin-left: calc(4rem / 16);
    }

    /deep/ .van-checkbox__label {
      // color: #88888d !important;
      color: @sizeColor !important;
    }


    .user_agreement {
      display: flex;
      align-items: center;
      margin-left: calc(4rem / 16);
      margin-top: calc(20rem / 16);

      .checkbox {
        width: fit-content !important;
      }

      .link {
        font-size: calc(12rem / 16);
        // color: #323232;
        color: @sizeColor;
        text-decoration: underline;
        margin-left: 8px;
      }
    }

    @btn_height: calc(48rem / 16);
    @btn_fontFamily: PingFangSC-Medium;

    .btn_login {
      width: 100%;
      line-height: @btn_height;
      background: var(--newBtnBg);
      border-radius: 5px;
      text-align: center;
      font-size: calc(18rem / 16);
      color: var(--btnsizeColor);
      margin-top: calc(20rem / 16);
    }

    .btn_registe {
      margin-top: calc(20rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      .hr{
        color: @sizeColor;
        border-bottom: 1px solid #5d5d5d;
        padding-bottom: calc(12rem / 16);

      }
    }
  }

  .service_bar {
    border-radius: calc(8rem / 16);
    // padding: calc(13rem / 16) calc(16rem / 16);
    // background: #fff;
    // background: @bg;
    margin-top: calc(20rem / 16);
    display: flex;
    align-items: center;

    .iconfont {
      font-size: calc(38rem / 16);
      padding-right: calc(8rem / 16);
      color: var(--newTheme);
    }

    >.p {
      font-size: calc(16rem / 16);
      color: var(--newTheme);

      .p2 {
        margin-top: calc(4rem / 16);
      }
    }
  }

  .lan_bar {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}

/deep/ .van-picker__toolbar {
  background-color: @bg;

  .van-picker__cancel {
    color: @sizeColor;
  }

  .van-picker__confirm {
    color: @Color;
  }
}
</style>
